// https://jsfiddle.net/zw3v5xav/
// https://newspaint.wordpress.com/2014/05/22/writing-rotated-text-on-a-javascript-canvas/

/**
 * TODO:
 * Fix setting default on booleans ( not using || strategy )
 * Drawing segments in steps. Rating of 4 means 4 segments.
 */
import getNextPosition from './springPhysics'
import { hexToRgb } from './utils'
export default () => {
  let data = []
  const mouse = {
    x: null,
    y: null,
    oldx: null,
    oldy: null,
    pressed: false,
    click: false,
    clickIndex: null,
    categoryClick: false,
    categoryHover: false,
    segmentHover: false,
    hoverIndex: null,
    centerDist: null,
    dragging: false,
    dragIndex: null,
  }
  let animationFrame = null
  const requestAnimationFrame = window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame

  const cancelAnimationFrame = window.cancelAnimationFrame ||
    window.mozCancelAnimationFrame

  let canvas = null
  let ctx = null
  let cx = null
  let cy = null
  let chartRadius = null
  let radiusStep = null
  let radiusTick = null
  let doughnutHoleRadius = null
  let labelHeight = null
  let chartSizePx = null
  let steps = 7
  let ticks = 10
  let maxValue = 100
  // let snapToTicks = false
  let drawData = []
  let baseLineWidth = 1
  let xFactor = 1 // if canvas is different resolution than canvas dom element. Run resize() every dom el size changes.
  let yFactor = 1 // if canvas is different resolution than canvas dom element. Run resize() every dom el size changes.
  const images = {} // a place to store icon pngs
  const callbacks = {
    onHoverCategory: () => {},
    onClickCategory: () => {},
    onValueUpdate: () => {},
  }
  let canSelectCategory = false
  let highlightSelectedCategory = false
  let rotateSelectedCategoryToTop = false
  let showSecondLayer = false
  let canRate = false
  let showLabels = null
  let showLabelText = null
  let labelFontSize = null
  let labelTextMarginInner = 16
  let labelIconMarginInner = 16
  let labelMarginOuter = 16
  let showIcons = null
  let iconHeight = null
  let exportLineWidth = baseLineWidth
  let angleStep = null // how big is the angle of each category
  let angleEndPoint = 0 // placement of category with index 0. 0 == 12 o clock. Used to calculate animations
  let topIndex = null // index of category placed on 12 oclcok. Used to calculate animations
  let highlightedIndex = null // index of category placed on 12 oclcok. Used to calculate animations
  let currentAngle = 0 // angle at current animation frame. Used to calculate animations
  let currentVelocity = 0 // used to calculate animations
  let currentDistance = 0 // used to calculate animations
  let chartSize = [10, 100] // size of chart in percentage of canvas width. First number is the doughnut hole
  let canvasXRes = null
  let canvasYRes = null
  let animate = false
  let firstDraw = true
  let frameworkId = 1
  let frameworkTitle = null

  const valueHoverColor = '#FDE274'
  const valueStrokeColor = '#FDE274'

  const defaults = {
    initAnimateFromCategory: 0,
    highlightSelectedCategory: false,
    rotateSelectedCategoryToTop: false,
    canSelectCategory: false,
    canRate: false,
    showLabels: true,
    baseLineWidth: 1,
    ticks: 10,
    maxValue: 100,
    chartSize: [10, 95],
    initSelectedCategory: 0,
    showIcons: true,
    showLabelText: true,
    iconHeight: 48,
    labelFontSize: 24,
    labelTextMarginInner: 16,
    labelIconMarginInner: 16,
    labelMarginOuter: 16,
    frameworkId: 1,
    frameworkTitle: null,
  }
  const initChart = (canvasEl, chartData, options) => {
    canvas = canvasEl
    data = chartData || data

    // setting options
    chartSize = options?.chartSize || defaults.chartSize
    ticks = options?.ticks || defaults.ticks
    maxValue = options?.maxValue || defaults.maxValue
    baseLineWidth = options?.baseLineWidth || defaults.baseLineWidth
    canvasXRes = options?.xRes || null
    canvasYRes = options?.yRes || null
    canSelectCategory = options?.canSelectCategory || defaults.canSelectCategory
    highlightSelectedCategory = options?.highlightSelectedCategory || defaults.highlightSelectedCategory
    rotateSelectedCategoryToTop = options?.rotateSelectedCategoryToTop || defaults.rotateSelectedCategoryToTop
    showSecondLayer = options?.showSecondLayer || defaults.showSecondLayer
    canRate = options?.canRate !== undefined ? options.canRate : defaults.canRate
    topIndex = options?.initAnimateFromCategory || defaults.initAnimateFromCategory
    showLabels = options?.showLabels !== undefined ? options.showLabels : defaults.showLabels
    labelFontSize = options?.labelFontSize !== undefined ? options.labelFontSize : defaults.labelFontSize
    showIcons = options?.showIcons !== undefined ? options.showIcons : defaults.showIcons
    showLabelText = options?.showLabelText !== undefined ? options.showLabelText : defaults.showLabelText
    labelTextMarginInner = options?.labelTextMarginInner !== undefined ? options.labelTextMarginInner : defaults.labelTextMarginInner
    labelIconMarginInner = options?.labelIconMarginInner !== undefined ? options.labelIconMarginInner : defaults.labelIconMarginInner
    labelMarginOuter = options?.labelMarginOuter !== undefined ? options.labelMarginOuter : defaults.labelMarginOuter
    iconHeight = options?.iconHeight !== undefined ? options.iconHeight : defaults.iconHeight
    frameworkId = options?.frameworkId !== undefined ? options.frameworkId : defaults.frameworkId
    frameworkTitle = options?.frameworkTitle !== undefined ? options.frameworkTitle : defaults.frameworkTitle
    angleStep = (Math.PI * 2) / data.length
    currentAngle = topIndex * angleStep
    currentDistance = topIndex * angleStep

    ctx = canvas.getContext('2d')
    steps = maxValue
    resize()
    setNewEndPoint(options?.initSelectedCategory || defaults.initSelectedCategory)
    addListeners()

    // loading icons before first render
    let loadedImages = 0
    const onImageLoad = (icon) => {
      return () => {
        images[icon].isLoaded = true
        ++loadedImages
        if (loadedImages === Object.keys(images).length) {
          firstDraw = true
        }
      }
    }
    drawData = []
    data.forEach(item => {
      drawData.push({
        title: item.title,
        label: item.label,
        color: item.color,
        icon: item.icon,
        value: item.value,
        visionValue: item.visionValue,
        series1Label: item.series1Label,
        series2Label: item.series2Label,
      })
      let icon = 'empty'
      if (item.icon !== '') {
        icon = item.icon
      }
      images[item.icon] = new Image()
      images[item.icon].src = frameworkId === 1 ? require('../assets/icons/' + icon + '.svg') : require('../assets/icons/' + icon + '.png')
      images[item.icon].crossOrigin = 'anonymous'
      images[item.icon].isLoaded = false
      images[item.icon].onload = onImageLoad(item.icon)
      images[item.icon].onerror = onImageLoad
    })
    firstDraw = true
    frameRender()
  }
  const updateDataValues = (updatedData) => {
    const arr = updatedData || data
    arr.forEach((item, index) => {
      if (item.value !== drawData[index].value) {
        drawData[index].value = item.value
      }
      if (item.visionValue !== drawData[index].visionValue) {
        drawData[index].visionValue = item.visionValue
      }
    })
  }
  const setCanvasResolution = () => {
    const rect = canvas.getBoundingClientRect()
    const domWidth = rect.right - rect.left
    // const domHeight = rect.bottom - rect.top
    canvas.width = canvasXRes || domWidth * 2
    canvas.height = canvasYRes || domWidth * 2
  }
  const resize = (XRes, YRes) => {
    // if (XRes !== undefined) canvasXRes = XRes
    // if (YRes !== undefined) canvasYRes = YRes
    setCanvasResolution()
    iconHeight = canvas.width / 18
    labelFontSize = canvas.width / 32
    exportLineWidth = baseLineWidth * canvas.width * 0.003
    cx = canvas.width / 2
    cy = canvas.height / 2
    const rect = canvas.getBoundingClientRect()
    xFactor = (canvas.width / (rect.right - rect.left))
    yFactor = (canvas.height / (rect.bottom - rect.top))
    chartSizePx = canvas.width * (chartSize[1] / 100)
    labelHeight = 0
    if (showIcons) labelHeight += iconHeight + labelIconMarginInner
    if (showLabelText) labelHeight += labelTextMarginInner + (labelFontSize * 1.2)
    if (showIcons || showLabelText) labelHeight += labelMarginOuter
    // TO DO: gør det muligt at sætte text-højde, mellemrum og iconhøjde seperat
    chartRadius = chartSizePx / 2
    if (showLabels) chartRadius -= labelHeight
    doughnutHoleRadius = (canvas.width * (chartSize[0] / 100)) / 2
    radiusStep = (chartRadius - doughnutHoleRadius) / steps
    radiusTick = (chartRadius - doughnutHoleRadius) / ticks
    firstDraw = true
  }
  const mouseDownCallBack = () => {
    mouse.pressed = true
  }
  const mouseUpCallback = () => {
    mouse.pressed = false
    mouse.click = true
  }
  const mouseMoveCallback = (e) => {
    const rect = canvas.getBoundingClientRect()
    mouse.x = (e.clientX - rect.left) * xFactor
    mouse.y = (e.clientY - rect.top) * yFactor
  }
  const mouseOutCallback = () => {
    mouse.x = null
    mouse.y = null
    mouse.categoryHover = false
    mouse.segmentHover = false
    firstDraw = true
  }
  const touchStartCallback = (evt) => {
    evt.preventDefault()
    const touches = evt.changedTouches
    const rect = canvas.getBoundingClientRect()
    mouse.x = (touches[0].clientX - rect.left) * xFactor
    mouse.y = (touches[0].clientY - rect.top) * yFactor
    mouse.pressed = true
  }
  const touchEndCallback = (evt) => {
    evt.preventDefault()
    console.log('touch ended')
    mouse.x = null
    mouse.y = null
    mouse.pressed = false
    mouse.click = true
  }
  const touchMoveCallback = (evt) => {
    evt.preventDefault()
    console.log('touch moving')
    const touches = evt.changedTouches
    const rect = canvas.getBoundingClientRect()
    mouse.x = (touches[0].clientX - rect.left) * xFactor
    mouse.y = (touches[0].clientY - rect.top) * yFactor
  }
  const addListeners = () => {
    canvas.addEventListener('mousedown', mouseDownCallBack)
    canvas.addEventListener('mouseup', mouseUpCallback)
    canvas.addEventListener('mousemove', mouseMoveCallback)
    canvas.addEventListener('mouseout', mouseOutCallback)
    canvas.addEventListener('touchstart', touchStartCallback, false)
    canvas.addEventListener('touchend', touchEndCallback, false)
    canvas.addEventListener('touchmove', touchMoveCallback, false)
  }
  const wrapText = (context, text, maxWidth, lineHeight) => {
    const words = text.split(' ')
    let line = ''
    let yPos = 0
    let totalHeight = 0
    const lines = []
    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' '
      const metrics = context.measureText(testLine)
      const testWidth = metrics.width
      if (testWidth > maxWidth && n > 0) {
        totalHeight += lineHeight
        lines.push({ line, yPos })
        line = words[n] + ' '
        yPos += lineHeight
      } else {
        line = testLine
      }
    }
    totalHeight += lineHeight
    lines.push({ line, yPos })
    return { yPos, totalHeight, lines }
  }
  const drawDoughnutSegment = (ctx, centerX, centerY, angles, radiuses, fillColor, strokeColor) => {
    const startAngle = angles.start
    const endAngle = angles.end
    const innerRadius = radiuses.inner > 0 ? radiuses.inner : 0
    const outerRadius = radiuses.outer > 0 ? radiuses.outer : 0
    ctx.beginPath()
    ctx.fillStyle = fillColor
    const startX = (Math.cos(startAngle) * outerRadius) + centerX
    const startY = (Math.sin(startAngle) * outerRadius) + centerY
    const endX = (Math.cos(endAngle) * innerRadius) + centerX
    const endY = (Math.sin(endAngle) * innerRadius) + centerY
    ctx.moveTo(startX, startY)
    ctx.arc(centerX, centerY, outerRadius, startAngle, endAngle, false)
    if (strokeColor) {
      ctx.strokeStyle = strokeColor
      ctx.lineWidth = xFactor !== Infinity ? baseLineWidth * xFactor : exportLineWidth
      ctx.stroke()
    }
    ctx.lineTo(endX, endY)
    ctx.arc(centerX, centerY, innerRadius, endAngle, startAngle, true)
    ctx.lineTo(startX, startY)
    ctx.fill()
    ctx.closePath()
  }
  const drawCategoryValue = (base, angles, value, color, strokeColor) => {
    if (value > maxValue) {
      value = maxValue
      strokeColor = '#FF0000'
    }
    const ctx = base.ctx
    const cx = base.cx
    const cy = base.cy
    const segmentRadius = base.doughnutHoleRadius + (base.radiusStep * value)
    const radiuses = { inner: base.doughnutHoleRadius, outer: segmentRadius }
    drawDoughnutSegment(ctx, cx, cy, angles, radiuses, color, strokeColor)
  }
  const drawCategoryHighLight = (base, angles, opacity) => {
    const ctx = base.ctx
    const cx = base.cx
    const cy = base.cy
    const fillColor = `rgba(0, 0, 0, ${opacity})`
    const radiuses = { inner: base.chartRadius, outer: base.chartSizePx / 2 }
    drawDoughnutSegment(ctx, cx, cy, angles, radiuses, fillColor)
  }
  const drawCategoryLabel = (base, angles, radius, label, icon) => {
    const ctx = base.ctx
    const cx = base.cx
    const cy = base.cy
    const startAngle = angles.start
    const endAngle = angles.end
    const theta = (startAngle + endAngle) / 2
    const circPointX = Math.cos(theta) * radius // if circle center is 0,0
    const circPointY = Math.sin(theta) * radius // if circle center is 0,0
    const realCircPointX = circPointX + cx
    const realCircPointY = circPointY + cy
    ctx.beginPath()
    ctx.save()
    ctx.translate(realCircPointX, realCircPointY)
    ctx.rotate(theta + (Math.PI / 2))
    if (showLabelText) {
      const labelY = 0 - labelTextMarginInner - (labelFontSize / 2)
      ctx.font = labelFontSize + 'px Helvetica, Calibri'
      ctx.textAlign = 'center'
      ctx.fillStyle = '#333'
      ctx.fillText(label + '.', 0, labelY)
    }
    if (showIcons) {
      let iconY = 0 - labelIconMarginInner - iconHeight
      if (showLabelText) iconY -= labelTextMarginInner + (labelFontSize * 1.2)
      const iconWidth = iconHeight * (images[icon].naturalWidth / images[icon].naturalHeight)
      const iconX = 0 - (iconWidth / 2)
      if (images[icon].isLoaded) {
        ctx.drawImage(images[icon], iconX, iconY, iconWidth, iconHeight)
      }
    }
    ctx.restore() // un-rotate and un-translate
    ctx.closePath()
  }
  const drawCategorySplitLine = (base, startAngle, radius) => {
    const ctx = base.ctx
    const cx = base.cx
    const cy = base.cy
    const lineLength = (radius + (labelHeight / 4))
    const px2 = (Math.cos(startAngle) * lineLength) + cx
    const py2 = (Math.sin(startAngle) * lineLength) + cy
    ctx.lineWidth = baseLineWidth * xFactor
    ctx.strokeStyle = 'rgba(255, 255, 255, .2)'
    ctx.moveTo(cx, cy)
    ctx.lineTo(px2, py2)
    ctx.stroke()
  }
  const drawRadialCircles = (ctx, cx, cy, radiusTick) => {
    ctx.beginPath()
    ctx.strokeStyle = 'rgba(255, 255, 255, .2)'
    ctx.lineWidth = baseLineWidth * xFactor
    for (let i = 0; i <= ticks; i++) {
      let radius = (i * radiusTick) + doughnutHoleRadius
      if (radius < 0) radius = 0
      ctx.moveTo(cx + radius, cy)
      ctx.arc(cx, cy, radius, 0, 2 * Math.PI)
      ctx.stroke()
      if (i === 0) {
        ctx.fillStyle = '#FFF'
        ctx.fill()
      }
      if (mouse.categoryHover && i === ticks && !animate) {
        ctx.fillStyle = 'rgba(249,250,251, .65)'
        ctx.fill()
      }
    }
    ctx.closePath()
  }
  const redraw = (canvas, data, firstCatAngle) => {
    ctx.clearRect(0, 0, canvas.width, canvas.height)
    const base = {
      ctx,
      cx,
      cy,
      chartRadius,
      doughnutHoleRadius,
      radiusStep,
      chartSizePx,
    }
    let startAngle = ((Math.PI * 1.5) + firstCatAngle) - (0.5 * angleStep)
    data.forEach((category, index) => {
      const endAngle = startAngle + angleStep
      const angles = { start: startAngle, end: endAngle }
      if (showSecondLayer) {
        const { r, g, b } = hexToRgb(category.color)
        const series1Color = category.color
        const series1Value = category.value || 0.5
        const series2Color = `rgba(${r}, ${g}, ${b}, 0.5)`
        const series2Value = category.visionValue || 0.5
        drawCategoryValue(base, angles, series1Value, series1Color)
        drawCategoryValue(base, angles, series2Value, series2Color, valueStrokeColor)
      } else {
        drawCategoryValue(base, angles, category.value, category.color)
      }
      if (showLabels) {
        if (canSelectCategory && highlightSelectedCategory && index === highlightedIndex) {
          drawCategoryHighLight(base, angles, '0.15')
        } else if (mouse.categoryHover && index === mouse.hoverIndex && !animate) {
          drawCategoryHighLight(base, angles, '0.05')
        }
        drawCategoryLabel(base, angles, chartRadius, category.label, category.icon)
      }
      drawCategorySplitLine(base, startAngle, chartRadius)
      startAngle = endAngle
    })
    drawRadialCircles(ctx, cx, cy, radiusTick)
    if (mouse.categoryHover && !animate) {
      const item = data[mouse.hoverIndex]
      const fontSize = canvas.width / 20
      ctx.font = fontSize + 'px Helvetica, Calibri'
      ctx.textAlign = 'center'
      ctx.fillStyle = '#333'
      const wrappedText = wrapText(ctx, item.title, chartRadius, fontSize)
      const textY = cy - (wrappedText.totalHeight)
      wrappedText.lines.forEach(line => {
        ctx.fillText(line.line, cx, textY + line.yPos)
      })
      ctx.font = Math.floor(0.85 * fontSize) + 'px Helvetica, Calibri'
      ctx.fillText(item.series2Label + ': ' + item.visionValue + ' / ' + maxValue, cx, textY + wrappedText.totalHeight + (fontSize * 0.2))
      ctx.fillText(item.series1Label + ': ' + item.value + ' / ' + maxValue, cx, textY + wrappedText.totalHeight + (fontSize * 1.2))
    }
    if (frameworkTitle !== null) {
      drawFrameworkTitle(ctx)
    }
  }
  const updateMouse = () => {
    // https://stackoverflow.com/questions/42924801/how-to-add-a-tooltip-on-mouseover-of-each-slice-of-a-pie-chart-using-canvas
    if (mouse.x && mouse.y) {
      mouse.segmentHover = false
      mouse.hoverIndex = null
      mouse.categoryHover = false
      if (!mouse.click) {
        mouse.categoryClick = false
      }
      let x = mouse.x
      let y = mouse.y
      x -= cx // vector from pie center
      y -= cy
      const dist = Math.sqrt(x * x + y * y) // get distance from center
      mouse.centerDist = dist
      if (dist < (canvas.width / 2)) {
        let ang = Math.atan2(y, x) // get angle note y is first
        const angleStep = ((Math.PI * 2) / data.length)
        ang += (Math.PI * 2.5) + (0.5 * angleStep) // rotate 360 as atan2 starts at -Pi
        ang %= Math.PI * 2 // normalize to range 0 to 2Pi
        for (let i = 0; i < data.length; i++) {
          const tAng = i * angleStep
          const maxDragRadius = chartRadius
          const minDragRadius = doughnutHoleRadius
          if (
            (ang > tAng) &&
            (ang < tAng + angleStep)
          ) {
            if (dist > minDragRadius && dist < maxDragRadius) {
              mouse.segmentHover = true
            } else if (dist > maxDragRadius + 10 && dist < (chartSizePx / 2)) {
              if (!mouse.dragging) mouse.categoryHover = true
              if (mouse.pressed && mouse.clickIndex === null) mouse.categoryClick = true
            }
            const newHitIndex = i + topIndex
            mouse.hoverIndex = newHitIndex >= data.length ? newHitIndex - data.length : newHitIndex
            if (mouse.pressed && mouse.clickIndex === null) {
              mouse.clickIndex = newHitIndex >= data.length ? newHitIndex - data.length : newHitIndex
            }
          }
        }
      }
      if (mouse.x !== mouse.oldx || mouse.y !== mouse.oldy) {
        // if something should only happen on mouse move
      }
    }
  }
  const resetDrawDataColor = () => {
    drawData.forEach((item, index) => {
      item.color = data[index].color
    })
  }
  const setNewEndPoint = (newIndex) => {
    currentAngle = topIndex * angleStep
    angleEndPoint = -1 * (newIndex * angleStep)
    const indexDiff = newIndex - topIndex
    if (indexDiff > (data.length / 2)) currentDistance = (indexDiff - data.length) * angleStep
    else if (indexDiff < -(data.length / 2)) currentDistance = (indexDiff + data.length) * angleStep
    else currentDistance = indexDiff * angleStep
    topIndex = newIndex
  }
  const updateCurrentAngle = () => {
    if (currentDistance.toFixed(3) !== '0.000') {
      const nextPos = getNextPosition(2000, 40, 350, currentDistance, currentVelocity)
      // more springy version
      // const nextPos = getNextPosition(2000, 40, 200, currentDistance, currentVelocity)
      currentDistance = nextPos.x
      currentVelocity = nextPos.v
      currentAngle = (angleEndPoint + currentDistance)
    } else {
      currentAngle = angleEndPoint
      animate = false
    }
  }
  const frameRender = () => {
    if (data.length) {
      updateMouse()
      resetDrawDataColor()
      if (mouse.categoryHover) {
        if (callbacks.onHoverCategory) callbacks.onHoverCategory(mouse.hoverIndex)
        if (canSelectCategory) document.body.style.cursor = 'pointer'
      } else if (mouse.segmentHover && canRate) {
        document.body.style.cursor = 'pointer'
      } else {
        document.body.style.cursor = 'default'
      }
      if (mouse.categoryClick && mouse.clickIndex !== null && canSelectCategory) {
        if (rotateSelectedCategoryToTop) {
          setNewEndPoint(mouse.clickIndex)
          animate = true
        }
        if (highlightSelectedCategory) highlightedIndex = mouse.clickIndex
        const catId = data[mouse.clickIndex].id
        callbacks.onClickCategory(catId)
      }

      // hover and drag category value
      if (mouse.segmentHover && mouse.hoverIndex !== null && !mouse.dragging & canRate) {
        if (mouse.pressed && canRate) {
          mouse.dragging = true
          mouse.dragIndex = mouse.hoverIndex
        }
        drawData[mouse.hoverIndex].color = valueHoverColor
      }
      if (mouse.pressed && mouse.dragging) {
        let newValue = Math.floor((mouse.centerDist - doughnutHoleRadius) / radiusStep) + 1
        if (newValue > maxValue) newValue = maxValue
        if (drawData[mouse.dragIndex].visionValue !== newValue) {
          drawData[mouse.dragIndex].visionValue = newValue
          callbacks.onValueUpdate(data[mouse.dragIndex], newValue)
        }
      } else if (!mouse.pressed && mouse.dragging) {
        mouse.dragging = false
        mouse.dragIndex = null
      }
      if (
        mouse.categoryHover ||
        mouse.categoryClick ||
        mouse.dragging ||
        mouse.segmentHover ||
        animate ||
        firstDraw
      ) {
        updateCurrentAngle()
        redraw(canvas, drawData, currentAngle)
        firstDraw = false
      }
      if (mouse.click) {
        mouse.click = false
        mouse.clickIndex = null
      }
    }
    animationFrame = requestAnimationFrame(frameRender)
  }
  const onHoverCategory = (callback) => {
    callbacks.onHoverCategory = callback
  }
  const onClickCategory = (callback) => {
    callbacks.onClickCategory = callback
  }
  const onValueUpdate = (callback) => {
    callbacks.onValueUpdate = callback
  }
  const destroyChart = () => {
    cancelAnimationFrame(animationFrame)
    if (canvas) {
      const ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.resetTransform()
      canvas.removeEventListener('mousedown', mouseDownCallBack)
      canvas.removeEventListener('mouseup', mouseUpCallback)
      canvas.removeEventListener('mousemove', mouseMoveCallback)
      canvas.removeEventListener('touchstart', touchStartCallback)
      canvas.removeEventListener('touchend', touchEndCallback)
      canvas.removeEventListener('touchmove', touchMoveCallback)
    }
  }
  const resetCategorySelect = () => {
    setNewEndPoint(0)
    animate = true
    highlightedIndex = null
  }
  const setCategorySelect = (index) => {
    setNewEndPoint(index)
    animate = true
    highlightedIndex = index
  }
  const drawFrameworkTitle = (ctx) => {
    ctx.font = labelFontSize + 20 + 'px Helvetica, Calibri'
    ctx.textAlign = 'center'
    ctx.fillStyle = '#333'
    ctx.fillText(frameworkTitle, canvas.width / 2, canvas.height - 50)
  }

  return { initChart, onHoverCategory, onClickCategory, onValueUpdate, resize, updateDataValues, destroyChart, resetCategorySelect, setCategorySelect }
}
