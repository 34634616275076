export const defaultWorkshopSteps = (t) => {
  return [
    {
      name: 'vision',
      label: t('Workshop.Steps.Step1Label'),
      description: t('Workshop.Steps.Step1Description'),
      subSteps: [],
      selectedSubSteps: [],
    },
    /* {
      name: 'visionrose',
      label: t('Workshop.Steps.Step2Label'),
      description: t('Workshop.Steps.Step2Description'),
      subSteps: [],
      selectedSubSteps: [],
    }, */
    {
      name: 'guide',
      label: t('Workshop.Steps.Step3Label'),
      description: t('Workshop.Steps.Step3Description'),
      subSteps: [
        { name: 'intro', label: t('Workshop.Steps.StepGuideSubStep1Label') },
        { name: 'omraader', label: t('Workshop.Steps.StepGuideSubStep2Label') },
        { name: 'ambition', label: t('Workshop.Steps.StepGuideSubStep4Label') },
        { name: 'tiltag', label: t('Workshop.Steps.StepGuideSubStep3Label') },
      ],
      selectedSubSteps: ['intro', 'omraader', 'ambition', 'tiltag'],
    },
    {
      name: 'opsamling',
      label: t('Workshop.Steps.Step4Label'),
      description: t('Workshop.Steps.Step4Description'),
      subSteps: [],
      selectedSubSteps: [],
    },
  ]
}

export const specialOtherSubcats = {
  1: 102,
  2: 103,
  3: 104,
  4: 105,
  5: 106,
  6: 107,
  7: 108,
  8: 109,
  9: 110,
  10: 111,
  11: 112,
  12: 113,
  13: 114,
  14: 115,
}
