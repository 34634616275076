<template>
  <div class="relative text-sm custom-arrow">
    <Multiselect
      :modelValue="selected"
      @update:modelValue="select"
      :options="options"
      ref="multiselect"
      :searchable="searchable"
      :canClear="canClear"
      :canDeselect="canDeselect"
      :placeholder="placeholder"
    >
    </Multiselect>
    <BaseIcon
      :icon="'BaseChevronDown'"
      class="absolute right-0 top-1 h-5 w-5 cursor-pointer"
      :color="darkBg ? 'white' : 'gray-800'"
      @click="open"
    />
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import BaseIcon from './BaseIcon.vue'

export default {
  data () {
    return {
      selected: '',
    }
  },
  components: {
    Multiselect,
    BaseIcon,
  },
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array, /* [{ label: string, value: string | number }] */
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    placeholderColor: {
      type: String,
      default: '#777777',
    },
    darkBg: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    canDeselect: {
      type: Boolean,
      default: false,
    },
    canClear: {
      type: Boolean,
      default: false,
    },
    borderBottom: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    open () {
      this.$refs.multiselect.open()
    },
    select (newValue) {
      this.$emit('update:modelValue', newValue)
    },
    setDynamicStyles () {
      let selectEl, inputEl
      if (this.$refs.multiselect) selectEl = this.$refs.multiselect.$el
      if (selectEl) inputEl = selectEl.querySelector('.multiselect-input')
      if (inputEl && this.borderBottom) inputEl.style.borderColor = this.darkBg ? 'white' : 'gray'
    },
  },
  emits: ['update:modelValue'],
  watch: {
    modelValue (newValue) {
      this.selected = newValue
    },
  },
  beforeMount () {
    this.selected = this.modelValue
  },
  mounted () {
    this.setDynamicStyles()
  },
}
</script>

<style>
.multiselect {
  position: relative;
  margin: 0 auto;
  font-size: 0;
}

.multiselect > * {
  font-size: initial;
}

.multiselect.is-searchable {
    cursor: auto;
}

.multiselect-input {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 2rem;
  border-top: none;
  border-left: none;
  border-right: none;
  background: none;
  border-radius: 0;
  border-bottom: 2px solid;
  border-color: rgba(255,255,255,0);
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  outline: none;
}

.multiselect-caret {
  position: absolute;
  right: 12px;
  top: 50%;
  color: #999;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #999 transparent transparent;
  content: "";
  transform: translateY(-50%);
  transition: .3s transform;
}
.custom-arrow .multiselect-caret {
  display: none;
}

.is-disabled .multiselect-input {
  background: #f9f9f9;
}

/* .is-open .multiselect-input {
  border-radius: 1rem 1rem 0 0;
} */

.is-open .multiselect-caret {
  transform: translateY(-50%) rotate(180deg);
}

.multiselect-placeholder,
.multiselect-single-label,
.multiselect-multiple-label {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 0;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  background: transparent;
}

.multiselect-placeholder {
  color: placeholderColor;
}

.is-single .multiselect-search,
.is-multiple .multiselect-search {
  display: flex;
  height: 100%;
  width: 100%;
  background: transparent;
}

.is-single .multiselect-search input,
.is-multiple .multiselect-search input {
  width: 100%;
  border: 0;
  padding: 0.5rem 0.2rem;
  height: 2rem;
  outline: none;
  background: transparent;
  font-size: 0.85rem;
  line-height: 1rem;
  font-family: inherit;
}

.is-single.no-caret .multiselect-search input,
.is-multiple.no-caret .multiselect-search input {
  padding: 8px 14px 8px 14px;
}

.is-tags .multiselect-search {
  flex-grow: 1;
}

.is-tags .multiselect-search input {
  outline: none;
  border: 0;
  margin: 0 0 5px 3px;
  flex-grow: 1;
  min-width: 100%;
  font-family: inherit;
}

.multiselect-clear {
  position: absolute;
  right: 0;
  top: 50%;
  width: 36px;
  height: 36px;
  transform: translateY(-50%);
}
.multiselect-clear:before,
.multiselect-clear:after {
  position: absolute;
  top: 12px;
  left: 16px;
  content: " ";
  height: 12px;
  width: 2px;
  background-color: #999;
}

.multiselect-clear:before {
  transform: rotate(45deg);
}

.multiselect-clear:after {
  transform: rotate(-45deg);
}

.multiselect-clear:hover:before,
.multiselect-clear:hover:after {
  background-color: #555;
}

.multiselect-tags {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding-left: 9px;
  margin-top: 5px;
  flex-wrap: wrap;
  padding-right: 36px;
}

.no-caret .multiselect-tags {
  padding-right: 9px;
}

.multiselect-tag {
  background: #41b883;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  padding: 0 0 0 8px;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  cursor: text;
  white-space: nowrap;
}

.multiselect-tag i {
  cursor: pointer;
}

.multiselect-tag i:before {
  content: "\D7";
  color: #266d4d;
  font-size: 14px;
  font-weight: 700;
  padding: 1px 5px 1px 5px;
  margin-left: 3px;
  display: flex;
  font-style: normal;
}

.multiselect-tag i:hover:before {
  color: #ffffff;
  background: rgba(255,255,255,0.2);
}

.is-disabled .multiselect-tag {
  background: #a0a0a0;
  padding: 1px 8px 1px 8px;
}

.multiselect-fake-input {
  background: transparent;
  width: 100%;
  height: 1px;
  border: 0;
  padding: 0;
  font-size: 0;
  margin-top: -1px;
  outline: none;
}
.multiselect-fake-input:active, .multiselect-fake-input:focus {
  outline: none;
}

.multiselect-options {
  position: absolute;
  right: 0;
  min-width: 100%;
  border: 1px solid #e8e8e8;
  margin-top: -1px;
  max-height: 160px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 100;
  background: #aaa;
  display: flex;
  flex-direction: column;
   -ms-overflow-style: none;
  scrollbar-width: none;
}

.multiselect-options::-webkit-scrollbar {
  display: none;
}

.open-top .multiselect-options {
  transform: translateY(-100%);
  top: 2px;
  flex-direction: column-reverse;
}

.multiselect-option {
  display: flex;
  min-height: 40px;
  padding: 9px 12px;
  box-sizing: border-box;
  color: #222;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  cursor: pointer;
}

.multiselect-option.is-pointed {
  background: var(--mediumSteel);
}

.multiselect-option.is-disabled {
  background: #f9f9f9;
  color: #a6a6a6;
  cursor: not-allowed;
}

.multiselect-option.is-selected {
  background: var(--lightTurquoise);
  color: #ffffff;
}

.multiselect-option.is-selected.is-pointed {
  background: var(--lightTurquoise);
}

.is-multiple .multiselect-option.is-selected,
.is-tags .multiselect-option.is-selected {
  color: #999999;
  background: transparent;
}

.is-multiple .multiselect-option.is-selected.is-pointed,
.is-tags .multiselect-option.is-selected.is-pointed {
  background: #f1f1f1;
}

.multiselect-no-options,
.multiselect-no-results {
  display: flex;
  padding: 10px 12px;
  color: #777;
}

.multiselect-spinner {
  position: absolute;
  right: 12px;
  top: 0;
  width: 16px;
  height: 16px;
  background: #fff;
  display: block;
  transform: translateY(50%);
}

.multiselect-spinner:before,
.multiselect-spinner:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: #41b883 transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}

.is-disabled .multiselect-spinner {
  background: #f9f9f9;
}

.is-disabled .multiselect-spinner:before,
.is-disabled .multiselect-spinner:after {
  border-color: #999999 transparent transparent;
}

.multiselect-spinner:before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}
.multiselect-spinner:after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}

.multiselect-enter-active {
  transition: all 0.15s ease;
}

.multiselect-leave-active {
  transition: all 0s;
}

.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}

.multiselect-loading-enter-active,
.multiselect-loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect-loading-enter,
.multiselect-loading-leave-active {
  opacity: 0;
}

@keyframes spinning {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}

</style>
